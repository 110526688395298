<template>
  <multiselect
    :filterable="true"
    :options="items"
    v-model="selected"
    :custom-label="label"
    :placeholder="'Select SAF Type'"
    @input="emitSelected" />
</template>

<script>
export default {
  data() {
    return {
      items: ['Adjustment Stock', 'Interwarehouse'].map(item => ({label: item})),
      selected: null
    };
  },
  methods: {
    label(option) {
      return option.label;
    },
    emitSelected(value) {
      this.$emit('selected', value);
    }
  },
  watch: {
    selected(newVal) {
      this.$emit('selected', newVal);
    }
  }
};
</script>
