<template>
  <vx-card :title="title">
    <vs-tabs v-model="activeTab">
      <vs-tab label="Import">
        <Import></Import>
      </vs-tab>
      <vs-tab label="Log">
        <Log
          :baseUrl="this.baseUrl"
          :draw="draw"
          @logImportLine="handleLogImportLine"
        ></Log>
      </vs-tab>
      <vs-tab label="Log Import Line">
        <ImportLine
          :baseUrl="this.baseUrl"
          :logImportID="this.logImportID"
        ></ImportLine>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import Import from "./component/Import.vue"
import Log from "./component/Log.vue"
import ImportLine from "./component/ImportLine.vue"
import DataTableLogImportLine from "../../import/user/role/Detail.vue";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    DataTableLogImportLine,
    Import,
    Log,
    ImportLine,
  },
  data() {
    return {
      title: "Import Stock Adjustment",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      activeTab: 0,

      month: null,

      file: null,
      draw: 0,
      logImportID: 0,
    };
  },
  methods: {
    setDefault() {
      this.activeTab = 0;
      this.month = null;
      this.file = null;
      this.logImportID = 0;
      this.$refs.fileInput.value = null;
    },
    handleLogImportLine(id) {
      this.activeTab = 2;
      this.logImportID = id;
    },
  }
}
</script>
