<template>
  <div class="vx-row mb-6">
    <div class="vx-col sm:w-1/5 w-full">
      <label
      >Select File
        <small style="color: red"
        >(only:xlsx, xls)</small
        ></label
      >
    </div>
    <div class="vx-col sm:w-4/5 w-full">
      <div class="vx-col w-1/2">
        <input
          id="fileInput"
          name="file"
          class="w-full inputx"
          type="file"
          ref="file"
          multiple="multiple"
          accept=".xlsx, .xls"
          @change="handleFileSelect"
        />
      </div>
    </div>
    <div class="vx-col sm:w-4/5 w-full mt-4">
      <div class="flex">
        <vs-button class="mr-3 mb-2" @click="handleDownloadTemplate">Download Template Stock Adjustment</vs-button>
        <vs-button class="mr-3 mb-2" @click="handleDownloadTemplateInterWarehouse">Download Template Interwarehouse</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  methods: {
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.$emit('input', file);
    },
    handleDownloadTemplate() {
      this.$vs.loading();
      var fileTitle = "SAF_IMPORT_STOCK_ADJUSTMENT " + moment().format("YYYY-MM-DD");

      this.$http
        .get("/api/wms/v1/stock-adjustment-form/template", {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    handleDownloadTemplateInterWarehouse() {
      this.$vs.loading();
      var fileTitle = "SAF_IMPORT_INTERWAREHOUSE " + moment().format("YYYY-MM-DD");

      this.$http
        .get("/api/wms/v1/stock-adjustment-form/template-interwarehouse", {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            var fileURL = window.URL.createObjectURL(new Blob([resp]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;

            fileLink.setAttribute(
              "download",
              fileTitle.toUpperCase() + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
  },
};
</script>
